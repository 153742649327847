//
// _buttons.scss
//

button,
a {
  outline: none !important;
}

// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

.btn-dark,
.btn-secondary {
  padding-left: 16px;
  padding-right: 16px;
  white-space: nowrap;
  transition-property: background-color, opacity;
  color: #000 !important;
  background-color: #eff0f1;
  border-radius: 50px;
  font-weight: normal;
  &:active {
    background-color: #deebff !important;
    border-color: #deebff !important;
    box-shadow: #deebff !important;
  }
  &:hover {
    background-color: #deebff;
  }
}

.btn-outline-light {
  color: $gray-900;
}

.btn-outline-dark,
.btn-dark {
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle,
  &:hover {
    color: $light;
  }
}

// example 1

.btn-group-example {
  .btn {
    position: relative;

    &:first-child {
      &::before {
        display: none;
      }
    }

    &:before {
      content: "OR";
      position: absolute;
      font-size: 10px;
      width: 24px;
      height: 24px;
      line-height: 24px;
      border-radius: 50%;
      background-color: $gray-600;
      color: $light;
      border: 1px solid $white;
      left: -12px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }
  }
}

// Example 2

.btn-label {
  position: relative;
  padding-left: 44px;
  .label-icon {
    position: absolute;
    width: 32px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba($white, 0.1);
    border-right: 1px solid rgba($white, 0.1);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.btn-light {
    .label-icon {
      background-color: rgba($dark, 0.1);
      border-right: 1px solid rgba($dark, 0.2);
    }
  }
}
